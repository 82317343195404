define("liquid-fire/action", ["exports", "liquid-fire/promise"], function (exports, _liquidFirePromise) {
  "use strict";

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var key in props) {
        var prop = props[key];prop.configurable = true;if (prop.value) prop.writable = true;
      }Object.defineProperties(target, props);
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  var _classCallCheck = function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  };

  var Action = (function () {
    function Action(nameOrHandler) {
      var args = arguments[1] === undefined ? [] : arguments[1];
      var opts = arguments[2] === undefined ? {} : arguments[2];

      _classCallCheck(this, Action);

      if (typeof nameOrHandler === "function") {
        this.handler = nameOrHandler;
      } else {
        this.name = nameOrHandler;
      }
      this.reversed = opts.reversed;
      this.args = args;
    }

    _createClass(Action, {
      validateHandler: {
        value: function validateHandler(transitionMap) {
          if (!this.handler) {
            this.handler = transitionMap.lookup(this.name);
          }
        }
      },
      run: {
        value: function run(context) {
          var _this = this;

          return new _liquidFirePromise["default"](function (resolve, reject) {
            _liquidFirePromise["default"].resolve(_this.handler.apply(context, _this.args)).then(resolve, reject);
          });
        }
      }
    });

    return Action;
  })();

  exports["default"] = Action;
});
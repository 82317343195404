define("liquid-fire/dsl", ["exports", "liquid-fire/animate", "liquid-fire/rule", "liquid-fire/constraint", "liquid-fire/action"], function (exports, _liquidFireAnimate, _liquidFireRule, _liquidFireConstraint, _liquidFireAction) {
  "use strict";

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var key in props) {
        var prop = props[key];prop.configurable = true;if (prop.value) prop.writable = true;
      }Object.defineProperties(target, props);
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  var _classCallCheck = function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  };

  var DSL = (function () {
    function DSL(map) {
      _classCallCheck(this, DSL);

      this.map = map;
    }

    _createClass(DSL, {
      setDefault: {
        value: function setDefault(props) {
          (0, _liquidFireAnimate.setDefaults)(props);
        }
      },
      transition: {
        value: function transition() {
          var rule = new _liquidFireRule["default"]();
          var parts = Array.prototype.slice.apply(arguments).reduce(function (a, b) {
            return a.concat(b);
          }, []);

          for (var i = 0; i < parts.length; i++) {
            rule.add(parts[i]);
          }

          this.map.addRule(rule);
        }
      },
      fromRoute: {
        value: function fromRoute(routeName) {
          return [new _liquidFireConstraint["default"]("oldRoute", routeName)];
        }
      },
      toRoute: {
        value: function toRoute(routeName) {
          return [new _liquidFireConstraint["default"]("newRoute", routeName)];
        }
      },
      withinRoute: {
        value: function withinRoute(routeName) {
          return this.fromRoute(routeName).concat(this.toRoute(routeName));
        }
      },
      fromValue: {
        value: function fromValue(matcher) {
          return [new _liquidFireConstraint["default"]("oldValue", matcher)];
        }
      },
      toValue: {
        value: function toValue(matcher) {
          return [new _liquidFireConstraint["default"]("newValue", matcher)];
        }
      },
      betweenValues: {
        value: function betweenValues(matcher) {
          return this.fromValue(matcher).concat(this.toValue(matcher));
        }
      },
      fromModel: {
        value: function fromModel(matcher) {
          return [new _liquidFireConstraint["default"]("oldModel", matcher)];
        }
      },
      toModel: {
        value: function toModel(matcher) {
          return [new _liquidFireConstraint["default"]("newModel", matcher)];
        }
      },
      betweenModels: {
        value: function betweenModels(matcher) {
          return this.fromModel(matcher).concat(this.toModel(matcher));
        }
      },
      hasClass: {
        value: function hasClass(name) {
          return new _liquidFireConstraint["default"]("parentElementClass", name);
        }
      },
      matchSelector: {
        value: function matchSelector(selector) {
          return new _liquidFireConstraint["default"]("parentElement", function (elt) {
            return elt.is(selector);
          });
        }
      },
      childOf: {
        value: function childOf(selector) {
          return this.matchSelector(selector + " > *");
        }
      },
      use: {
        value: function use(nameOrHandler) {
          for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            args[_key - 1] = arguments[_key];
          }

          return new _liquidFireAction["default"](nameOrHandler, args);
        }
      },
      reverse: {
        value: function reverse(nameOrHandler) {
          for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            args[_key - 1] = arguments[_key];
          }

          return new _liquidFireAction["default"](nameOrHandler, args, { reversed: true });
        }
      },
      useAndReverse: {
        value: function useAndReverse(nameOrHandler) {
          for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            args[_key - 1] = arguments[_key];
          }

          return [this.use.apply(this, [nameOrHandler].concat(args)), this.reverse.apply(this, [nameOrHandler].concat(args))];
        }
      },
      onInitialRender: {
        value: function onInitialRender() {
          return new _liquidFireConstraint["default"]("firstTime", "yes");
        }
      },
      includingInitialRender: {
        value: function includingInitialRender() {
          return new _liquidFireConstraint["default"]("firstTime", ["yes", "no"]);
        }
      },
      inHelper: {
        value: function inHelper() {
          for (var _len = arguments.length, names = Array(_len), _key = 0; _key < _len; _key++) {
            names[_key] = arguments[_key];
          }

          return new _liquidFireConstraint["default"]("helperName", names);
        }
      },
      toModal: {
        value: function toModal(matcher) {
          return new _liquidFireConstraint["default"]("newModalComponent", matcher);
        }
      },
      fromModal: {
        value: function fromModal(matcher) {
          return new _liquidFireConstraint["default"]("oldModalComponent", matcher);
        }
      },
      debug: {
        value: function debug() {
          return "debug";
        }
      }
    });

    return DSL;
  })();

  exports["default"] = DSL;
});